<template>
    <v-card tile class="pa-3 ma-10">
        <v-card-title>
            ダッシュボード
        </v-card-title>
        <v-data-table
            show-select
            :headers="status.headers"
            :items="status.items"
            >
        </v-data-table>
    </v-card>
</template>

<script>

export default {
    data: () => ({
        status: {
            headers: [
                { text: "年", value: "year" },
                { text: "週番号", value: "week" },
                { text: "アップロードファイル名", value: "filename" },
                { text: "ファイルアップロード日時", value: "date" },
                { text: "ステータス", value: "status" },
            ],
            items: []
        }
    })
}
</script>
